import { render, staticRenderFns } from "./RatePageFooter.vue?vue&type=template&id=5845c44e&scoped=true"
import script from "./RatePageFooter.vue?vue&type=script&lang=js"
export * from "./RatePageFooter.vue?vue&type=script&lang=js"
import style0 from "./RatePageFooter.vue?vue&type=style&index=0&id=5845c44e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5845c44e",
  null
  
)

export default component.exports