<script>
export default {
  name: "RatePageSection",
  
  props: {
    title: {
      type: String,
      required: true
    }
  },
  
  data() {
    return {
    }
  },
}
</script>

<template>
  <div class="section">
    <div class="section__title">{{title}}</div>
    <div class="section__body">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.section {
  border-radius: 24px;
  background: #FFFFFF;

  @media print {
    border-radius: 12px;
    border: 1px solid var(--grey-20);
  }
  
  &__title {
    color: #0F0F0F;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;
    padding: 20px 80px;
    border-radius: 24px 24px 0 0;
    background: var(--grey-20);

    @media print {
      padding: 6px 32px;
      font-size: 14px;
      border-radius: 12px 12px 0 0;
    }
    
    @media not print and (max-width: $tablet-width) {
      padding: 20px 32px;
    }
  }
  
  &__body {
    padding: 20px 40px;
    background: #FFFFFF;
    border-radius: 24px;
    
    @media (max-width: $tablet-width) {
      padding: 25px;
    }
    
    @media (max-width: $mobile-width) {
      padding: 16px;
    }
  }
}
</style>
