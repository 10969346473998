<script>
export default {
  name: "RateCardInfo.vue",
  
  props: {
    rate: {
      type: Object,
    },
  },
  
  data() {
    return {
      warnCircleIcon: require("../../assets/img/icons/warning-circle-orange.svg"),
    }
  },
  
  computed: {
    isAuth() {
      return !!this.$_getsetting('is_auth');
    },
    
    findAuto() {
      return this.rate.detail.some(service => service.type_id === 1222)
    },

    clientId() {
      return Number(this.$_getsetting('client_id'))
    }
  },
  
  methods: {
    getDate(value) {
      if (value != null)
        return (new Date(value)).toLocaleString("ru", {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timezone: 'UTC'
        });
      else
        return ' '
    },
  }
}
</script>

<template>
  <div>
    <div class="card-info">
      <div class="card-info__column">
        <div class="card-info__valid-date">
          <img alt="warning" :src="warnCircleIcon"/>
          <span v-if="(!rate.change_valid_date && !rate.change_valid_date_ext) || clientId === -1"> Ставка действительна до {{getDate(rate.date_to)}} </span>
          <span v-else-if="(!rate.change_valid_date && rate.change_valid_date_ext) || clientId === -1"> Ставка действительна до {{getDate(rate.date_to)}}. С {{getDate(rate.date_to)}} ставка будет действовать до последующего обновления. </span>
          <span v-else> Ставка действительна до последующего обновления </span>
        </div>
        <div class="card-info__grey-text">
          <div v-if="findAuto"> Автодоставка до склада не включена и рассчитывается отдельно</div>
          <div v-else> Автодоставка и терминальные расходы по станции назначения в&nbsp;стоимость не включены </div>
          <div> Место пересечения границы: {{rate.place_across_name}} </div>
          <div v-if="rate.is_backstage === 1 && isAuth === true"> Место постановки на ЖД: {{rate.across_place_name_ext}}</div>
        </div>
      </div>

      <div class="card-info__column" v-if="rate.all_comment.length > 0">
        <div class="card-info__warning">{{rate.all_comment}}</div>
      </div>
    </div>

    <div class="card-info">
      <div class="card-info__valid-date">
        <img alt="warning" :src="warnCircleIcon"/>
        <div class="card-info__grey-text">На сайте размещены тарифы перевозок, очищенные от НДС.
        Обложение оказываемых экспедиторами услуг налогом на добавленную стоимость осуществляется в соответствии с действующим законодательством Российской Федерации.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-info {
  padding: $size-24 $size-32;
  border-radius: 16px;
  border: 1px solid var(--orange-70);
  background: var(--orange-10);
  display: flex;

  &:last-child {
    margin-top: 22px;
  }

  @media print {
    padding: 8px $size-32;
  }
  
  @media (max-width: $tablet-width) {
    flex-direction: column;
  }
  
  &__column {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @media (max-width: $tablet-width) {
      width: 100%;
    }
    
    &:not(:last-child) {
      border-right: 1px solid #C1CAD8;
      padding-right: $size-32;
      
      @media (max-width: $tablet-width) {
        border-right: none;
        border-bottom: 1px solid #C1CAD8;
        padding-bottom: $size-16;
      }
    }
    
    &:not(:first-child) {
      padding-left: $size-32;
      
      @media (max-width: $tablet-width) {
        padding-left: 0;
        padding-top: $size-16;

        @media print {
          padding-top: 0;
        }
      }
    }
  }
  
  &__valid-date {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 8px;

    display: flex;
    align-content: center;
    align-items: center;

    > span {
      color: var(--orange-100);
      font-weight: 600;
    }
    
    @media print and (max-width: $mobile-width) {
      font-size: 12px;
      line-height: 14px;
    }
    
    > img {
      margin-right: $size-12;
      width: 16px;
      height: 16px;
    }
  }
  
  &__grey-text {
    color: var(--grey-70);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.14px;
    
    @media print and (max-width: $mobile-width) {
      font-size: 12px;
      line-height: 14px;
    }
  }
  
  &__warning {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.28px;
    text-align: left;
    max-width: 500px;
    color: var(--orange-70);

    @media print {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
