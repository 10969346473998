<script>
export default {
  name: 'RatePageAdditional',
  
  props: {
    additional: {
      type: [Object, Array]
    },
    
    is_detention: {
      type: Boolean,
      default: false
    }
  },
  
  methods: {
    formatItem(item){
      if (Number(item.days_from) !== 0){
        if (Number(item.days_to) !== 999)
          return 'c ' + item.days_from + ' по ' + item.days_to + ' день';
        else
          return 'cвыше ' + item.days_from + ' дней';
      }
      else
        return ' по ' + item.days_to + ' день';
    },
    
    getAllDays(item) {
      if (Number(item.days_from) !== 0){
        if (Number(item.days_to) !== 999) {
          return 'всего:  <b>' + (parseInt(item.days_to - item.days_from) ) + ' дней  </b>';
        }
        else return '';
      }
        else {
        return 'всего: <b>' + (parseInt(item.days_to - item.days_from)) + ' дней  </b>';
      }
    },
    
    getNumber(value) {
      return parseFloat(value).toLocaleString("ru-RU");
    }
  }
}
</script>

<template>
  <div class="additional" v-if="additional.length > 0">
    <div
      class="additional__container"
      :class="is_detention ? 'additional__container-blue' : 'additional__container-grey'"
      v-for="item in additional"
      :key="item.id"
    >
      <div class="additional__container__title">
        <div>
          {{is_detention ? 'Начало использования' : 'Начало хранения'}}
        </div>
        <div class="additional__container__title-comment">
          {{ item.comment }}
        </div>
      </div>
      <div class="additional__container__table">
        <div
          class="additional__container__table__line"
          v-for="(child, index) in item.child"
          :key="index"
        >
          <span>{{ formatItem(child) }}</span>
          <span v-html="getAllDays(child)"></span>
          <span>{{ getNumber(child.price) }}&nbsp;
            <span v-if="child.currency_id == '840'">&#36; / день</span>
            <span v-else-if="child.currency_id == '978'">&#8364; / день</span>
            <span v-else>&#8381; / день</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.additional {
  
  &__container {
    border-radius: 16px;
    padding: 34px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
    
    &-grey {
      background: var(--grey-10);
    }
    
    &-blue {
      background: var(--blue-10);
    }
    
    @media (max-width: $tablet-width) {
      flex-direction: column;
    }
    
    @media print {
      padding: 12px;
      margin-bottom: 0;
      background: none !important;
      border: 1px solid var(--grey-20);
    }
    
    &__title {
      color: var(--grey-100);
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.2px;
      width: 50%;
      
      @media (max-width: $tablet-width) {
        width: 100%;
        margin-bottom: 40px;
      }
      
      @media (max-width: $mobile-width) {
        font-size: 16px;
        margin-bottom: 30px;
      }

      @media print {
        font-size: 14px;
        margin-bottom: 12px;
      }
      
      &-comment {
        color: var(--grey-70);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.14px;
        margin-top: 14px;
        max-width: 430px;

        @media print {
          font-size: 12px;
          margin-top: 8px;
        }
      }
    }
    
    &__table {
      color: var(--grey-70);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.14px;
      width: 50%;
      
      @media (max-width: $tablet-width) {
        width: 100%;
      }
      
      @media (max-width: $mobile-width) {
        font-size: 12px;
      }

      @media print {
        font-size: 12px;
      }
      
      &__line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 16px;
        
        &:not(:first-child) {
          padding-top: 16px;
        }
        
        &:not(:last-child) {
          border-bottom: 1px solid var(--grey-50);
        }

        @media print {
          padding-top: 4px !important;
          padding-bottom: 4px !important;
        }
        
        > span {
          font-weight: 500;
          width: 40%;
          letter-spacing: 0.14px;
          color: var(--grey-70);
          
          &:nth-child(2) {
            width: 30%;
          }
          
          &:last-child {
            text-align: right;
            width: 30%;
          }
        }
      }
    }
  }
}
</style>
